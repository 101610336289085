export const education = [
   {
      description: 'Jacksonville, Florida',
      employer: 'University of North Florida',
      technologies: [],
      beginYear: 2001,
      endYear: 2004,
      title: 'Bachelor in Computer Science',
   },
   {
      description: 'San Antonio, Texas',
      employer: 'Community College of the Air Force',
      technologies: [],
      beginYear: 1992,
      endYear: 1997,
      title: 'Associate in Electronics',
   },
];
