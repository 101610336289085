export const materialUiBreakpoints = [
   {
      name: 'xs',
      minWidth: 0,
      maxWidth: 599,
   },
   {
      name: 'sm',
      minWidth: 600,
      maxWidth: 959,
   },
   {
      name: 'md',
      minWidth: 960,
      maxWidth: 1279,
   },
   {
      name: 'lg',
      minWidth: 1280,
      maxWidth: 1919,
   },
   {
      name: 'xl',
      minWidth: 1920,
      maxWidth: Number.MAX_SAFE_INTEGER,
   },
];
